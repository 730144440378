import styled from "styled-components";

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-weight: 700;
  /* box-shadow: 0px 0px 5px 5px lightgrey; */
`;

export const TableHeader = styled.thead`
  box-shadow: 0px 0px 5px 5px lightgrey;
  color: white;
`;

/* Even rows */
export const TableRow = styled.tr`
  &:nth-child(even) {
    box-shadow: 0px 0px 5px 5px lightgrey;
    color: white;
  }
`;

export const TableCell = styled.td`
  padding: 15px;
  border: 3px solid white;
  text-align: ${(props) => (props.alignCenter ? "center" : "left")};
`;

export const TableBody = styled.tbody``;

export const CalendarWrapper = styled.div`
  display: flex;
  width: 100%;
  background: white;
  padding: 10px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const FirstSection = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
export const SecondSection = styled.div`
  width: 100%;
  box-shadow: 0px 0px 5px 5px lightgrey;
  display: flex;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  .squareDesc {
    display: flex;
    margin-bottom: 10px;
    .squareDev {
      display: flex;
      margin-right: 15px;
      span {
        display: flex;
        width: 40px;
        height: 15px;
        background: rgb(251, 173, 96);
        margin-right: 5px;
      }
    }
    .squareCons {
      display: flex;
      span {
        display: flex;
        width: 40px;
        height: 15px;
        background: #3e98c7;
        margin-right: 5px;
      }
    }
  }

  .holsPlanned-Container {
    display: flex;
    flex-direction: column;
    h3 {
      margin-bottom: 10px;
      margin: 0 auto;
    }
  }
  .outsideWrapperHols {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .holsPlanned-Wrapper {
    display: flex;
    margin-top: 20px;
    div {
      margin-right: 20px;
    }
    .CircularProgressbar-text {
      font-size: 18px !important;
    }
  }
`;

export const ThirdSection = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const FirstTopWrapper = styled.div`
  width: 100%;
  height: 50%;
  box-shadow: 0px 0px 5px 5px lightgrey;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .linebreak {
    border: 1px solid black;
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25%;
  }

  p {
    font-size: 20px;
    width: 100%;
    text-align: left;
  }

  .CircularProgressbar-text {
    font-size: 16px !important;
  }
`;

export const FirstBotWrapper = styled.div`
  width: 100%;
  height: 50%;
  box-shadow: 0px 0px 5px 5px lightgrey;
  border-radius: 12px;
  padding: 24px;
  font-size: 14px;
  .linebreak {
    border: 1px solid black;
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .request {
    margin-top: 5px;
  }
  .bold {
    font-weight: 700;
    margin: 16px 0px;
  }
`;
export const ThirdTopSection = styled.div`
  width: 100%;
  height: 40%;
  box-shadow: 0px 0px 5px 5px lightgrey;
  margin-bottom: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .mainSectionWrapper {
    padding: 24px;
  }
  .endOfYear {
    font-size: 20px;
  }
  .contractualAllowance {
    font-size: 14px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }
  .linebreak {
    border: 1px solid black;
    width: 100%;
    display: flex;
    margin-top: 25px;
  }
  .total {
    font-size: 14px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .annualLeave {
    font-size: 14px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .daysLeftOfHols {
    height: 60px;
    background: #2b4162;
    align-items: center;
    display: flex;
    padding: 24px;
    font-size: 18px;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: space-between;
  }
`;
export const ThirdMidSection = styled.div`
  width: 100%;
  height: 25%;
  box-shadow: 0px 0px 5px 5px lightgrey;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 24px;

  .linebreak {
    border: 1px solid black;
    width: 100%;
    display: flex;
    margin-top: 15px;
  }

  .timeOffTracking {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .public_Holidays {
    font-size: 14px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .sicknessDays {
    font-size: 14px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
`;
export const ThirdBotSection = styled.div`
  width: 100%;
  box-shadow: 0px 0px 5px 5px lightgrey;
  border-radius: 12px;
  padding: 24px;

  .annualTrendMessage {
    font-size: 20px;
  }
  .linebreak {
    border: 1px solid black;
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .bar-chart-container {
    width: 100%;
    height: auto;
  }
`;

export const MonthTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  .linebreak {
    border: 1px solid black;
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  p {
    font-size: 36px;
    font-weight: 700;
    font-style: italic;
  }
`;
export const ChartContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  canvas {
    max-width: 100%;
  }
`;
export const HolidaysBooked = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 2px;
    font-size: 1rem;
  }
  h3 {
    margin-bottom: 5px;
  }
  span {
    font-weight: 700;
  }
`;

export const WorkingDaysAvailable = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  h3 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 5px;
    font-size: 1rem;
  }
  span {
    font-weight: 700;
  }
`;

export const Container = styled.div`
  padding: 20px;
`;

export const HolidaysPlanned = styled.div`
  margin-bottom: 20px;
  h3 {
    font-size: 20px;
  }
`;

export const WorkingDays = styled.div`
  margin-bottom: 20px;
  h3 {
    font-size: 20px;
  }
`;

export const AvailableResource = styled.div`
  h3 {
    font-size: 20px;
  }
`;

export const GraphContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

export const Graph = styled.div`
  width: 90px;
  text-align: center;
  cursor: pointer;
  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const AdminViewWrapper = styled.div`
  background: white;
  border-top: 2px solid black;
  padding: 10px;

  h3{
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`