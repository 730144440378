// import { React, useState, useEffect } from "react";
import "./Favourite.scss"

function Favourite({ isFavourite, onToggle }) {

  return (
    <div>
      <button onClick={onToggle}>
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={isFavourite ? "favourited" : "favourite"}
            id="Vector"
            d="M12.325 0C14.9073 0 17 2.2973 17 5.51351C17 11.9459 10.625 15.6216 8.5 17C6.375 15.6216 0 11.9459 0 5.51351C0 2.2973 2.125 0 4.675 0C6.256 0 7.65 0.918919 8.5 1.83784C9.35 0.918919 10.744 0 12.325 0ZM9.2939 14.3388C10.0428 13.8279 10.7185 13.3197 11.3509 12.7757C13.8848 10.5979 15.3 8.21789 15.3 5.51351C15.3 3.34486 13.9936 1.83784 12.325 1.83784C11.4104 1.83784 10.421 2.36162 9.7019 3.13719L8.5 4.43654L7.2981 3.13719C6.579 2.36162 5.5896 1.83784 4.675 1.83784C3.026 1.83784 1.7 3.35957 1.7 5.51351C1.7 8.21881 3.1161 10.5979 5.64825 12.7757C6.2815 13.3197 6.95725 13.8279 7.7061 14.3379C7.96025 14.5116 8.21185 14.6779 8.5 14.8635C8.78815 14.6779 9.03975 14.5116 9.2939 14.3388Z"
            fill={isFavourite ? "rgb(226, 6, 120)" : "#C4C4C4" }
          />
        </svg>
      </button>
    </div>
  );
}

export default Favourite;
