import React, { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { collection, onSnapshot, addDoc, deleteDoc, doc, getDocs, arrayUnion, arrayRemove, getDoc, updateDoc, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./ModalComponent.scss";
import FunctionItem from "../FunctionItem/FunctionItem";


function ModalComponent() {
  const [funcs, setFuncs] = useState([]);
  const [popupActive, setPopupActive] = useState(false);
  const [form, setForm] = useState({ name: "", func: "", desc: "" });
  const [favourites, setFavourites] = useState([]);
  const [user, setUser] = useState(null);
  const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);

  const funcsCollectionRef = collection(db, "functions");

  useEffect(() => {
    const unsubscribe = onSnapshot(funcsCollectionRef, (snapshot) => {
      setFuncs(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          viewing: false,
          ...doc.data(),
        }))
      );
    });
    return () => unsubscribe();
  }, []);

  const handleView = (id) => {
    setFuncs(funcs.map((func) => ({ ...func, viewing: func.id === id ? !func.viewing : false })));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.name || !form.func || !form.desc) {
      alert("Please fill out all the fields");
      return;
    }
    addDoc(funcsCollectionRef, form);
    setForm({ name: "", func: "", desc: "" });
    setPopupActive(false);
  };

  const removeFunc = (id) => {
    let result = window.confirm("Are you sure you want to delete?");
    if (result) {
      deleteDoc(doc(db, "functions", id));
    }
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  // Listen for Firebase auth state change
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // console.log("✅ User detected:", firebaseUser.uid);
        setUser(firebaseUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  useEffect(() => {
    if (!user) return; // Wait until we have a user

    const fetchFavourites = async () => {
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(usersCollectionRef, where("id", "==", user.uid));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setFavourites(userData.favourites || []);
      } 
    };

    fetchFavourites();
  }, [user]); // Runs only when `user` is set

  const toggleFavourite = async (func) => {
    const userQuery = query(collection(db, "users"), where("id", "==", user.uid));
    const querySnapshot = await getDocs(userQuery);
    if (querySnapshot.empty) {
      return;
    }

    const userDoc = querySnapshot.docs[0];
    const userRef = doc(db, "users", userDoc.id);
    const isFav = favourites.some((fav) => fav.id === func.id);

    try {
      if (isFav) {
        await updateDoc(userRef, {
          favourites: arrayRemove({ id: func.id, name: func.name }),
        });
      } else {
        await updateDoc(userRef, {
          favourites: arrayUnion({ id: func.id, name: func.name }),
        });
      }

      // Update state immediately
      setFavourites((prevFavourites) =>
        isFav
          ? prevFavourites.filter((fav) => fav.id !== func.id)
          : [...prevFavourites, { id: func.id, name: func.name }]
      );

    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  const favouriteFunctions = funcs.filter((func) =>
    favourites.some((fav) => fav.id === func.id)
  );

  return (
    <div className="App">
      <div className="hidden modalBackdrop"></div>
      <h1>Functions</h1>
      <button className="slide_left" onClick={() => setPopupActive(!popupActive)}>
        Add function
      </button>
      <button onClick={()=> setShowOnlyFavourites(!showOnlyFavourites)}>
        {showOnlyFavourites ? "Unfilter" : "Filter by Favourites"}
      </button>
    
      <div className="functions-wrapper">
        {showOnlyFavourites ? favouriteFunctions.map((func)=> (
          <FunctionItem 
          key={func.id} 
          func={func} 
          onView={handleView}
          onRemove={removeFunc}
          onCopy={handleCopyCode}
          isFavourite={favourites.some((fav) => fav.id === func.id)}
          onToggleFavourite={() => toggleFavourite(func)}
        />
        )) :
        funcs.map((func) => (
        <FunctionItem 
          key={func.id} 
          func={func} 
          onView={handleView}
          onRemove={removeFunc}
          onCopy={handleCopyCode}
          isFavourite={favourites.some((fav) => fav.id === func.id)}
          onToggleFavourite={() => toggleFavourite(func)}
        />
      ))}
      </div>
      {popupActive && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Add a function</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name</label>
                <input type="text" value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
              </div>
              <div className="form-group">
                <label>Function</label>
                <textarea
                  type="text"
                  value={form.func}
                  onChange={(e) => setForm({ ...form, func: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  type="text"
                  value={form.desc}
                  onChange={(e) => setForm({ ...form, desc: e.target.value })}
                />
              </div>
              <div className="buttons">
                <button typeof="submit">Submit</button>
                <button type="button" className="remove" onClick={() => setPopupActive(false)}>
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalComponent;
