
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Header/Header';
import "./Asana.scss";

const AsanaTaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [devCamps, setDevCamps] = useState([]);
  const [processedTasks, setProcessedTasks] = useState(new Set());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = 'https://app.asana.com/api/1.0/projects/1206149873258943/tasks';
        const accessToken = '1/1202728739608631:90c545d99e76bcb0477fe9cd6e24da8d';

        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        setTasks(response.data.data);
        setLoading(false);

        const customFieldApiUrl = 'https://app.asana.com/api/1.0/projects/1206149873258943/tasks?opt_fields=custom_fields.display_value';
        const customFieldOptions = {
          method: 'GET',
          headers: {
            accept: 'application/json',
            authorization: `Bearer ${accessToken}`,
          },
        };

        const customFieldResponse = await fetch(customFieldApiUrl, customFieldOptions);
        const customFieldData = await customFieldResponse.json();

        const devCamps = [];
        
        customFieldData.data.forEach(camp => {
          const custFields = camp.custom_fields;
          console.log(custFields);
          custFields.forEach(field => {
            if (field.gid === "1203392235812081") {
              devCamps.push({
                task: camp.gid,
                dev: field.display_value,
              });
            }
          });
        });

        setDevCamps(devCamps);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    const seconds = Math.floor((totalMinutes * 60) % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const fetchTimeEntries = async () => {
    const taskIds = devCamps.map(camp => camp.task);

    for (const taskId of taskIds) {
      if (processedTasks.has(taskId)) {
        console.log(`Task ${taskId} has already been processed`);
        continue;
      }

      try {
        const timeTrackingResponse = await fetch(`https://app.asana.com/api/1.0/tasks/${taskId}/time_tracking_entries`, {
          headers: {
            'Authorization': `Bearer 1/1202728739608631:90c545d99e76bcb0477fe9cd6e24da8d`,
            'Accept': 'application/json',
          },
        });

        if (!timeTrackingResponse.ok) {
          throw new Error(`Error: ${timeTrackingResponse.status} ${timeTrackingResponse.statusText}`);
        }

        const timeTrackingData = await timeTrackingResponse.json();
        console.log(timeTrackingData);

        const devCamp = devCamps.find(camp => camp.task === taskId);
        const task = tasks.find(t => t.gid === taskId);

        const result = {
          taskId,
          taskName: task ? task.name : 'Unknown Task',
          dev: devCamp.dev,
          totalTime: '00:00:00',
        };

        if (timeTrackingData.data && timeTrackingData.data.length > 0) {
          const totalMinutes = timeTrackingData.data.reduce((sum, entry) => sum + (entry.duration_minutes || 0), 0);
          result.totalTime = formatTime(totalMinutes);
        }

        console.log(result);

        setProcessedTasks(prevProcessedTasks => new Set(prevProcessedTasks).add(taskId));
      } catch (error) {
        console.error(`Error fetching time entries for task ${taskId}:`, error);
      }
    }
  };

  useEffect(() => {
    if (devCamps.length > 0) {
      setTimeout(fetchTimeEntries, 6000);
    }
  }, [devCamps]);

  const filteredTasks = tasks.filter(task => devCamps.some(devCamp => devCamp.task === task.gid));

  return (
    <>
      <Header />
      <div>
        <h2>Asana Task List</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {filteredTasks.map(task => (
              <li key={task.gid}>
                {task.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default AsanaTaskList;
