import React, { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import { collection, doc, getDocs, arrayUnion, arrayRemove, updateDoc, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { atelierForestDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Favourite from "../Favourite/Favourite";

// Function component for individual function item
export default function FunctionItem({ func, onView, onRemove, onCopy, isFavourite, onToggleFavourite, }) {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = () => {
    onCopy(func.func);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="function-elements">
      <h3 className="function__name" id={func.id}>{func.name}</h3>
      <div className="function__description-cont">
        <h4>Description</h4>
        <p className="function__description" dangerouslySetInnerHTML={{ __html: func.desc }}></p>
      </div>
      {func.viewing && (
        <div className="function__info">
          <div className="function__container function-code">
            <h4>Code</h4>
            <p className="function__function">
              <SyntaxHighlighter language="javascript" style={atelierForestDark}>
                {func.func}
              </SyntaxHighlighter>
            </p>
          </div>
        </div>
      )}
      <div className="buttons">
        <button className="view less" onClick={() => onView(func.id)}>
          View {func.viewing ? "less" : "more"}
        </button>
        <button className="remove" onClick={() => onRemove(func.id)}>
          Remove
        </button>
        {func.viewing && (
          <button className="copy" onClick={handleCopyCode}>
            Copy Code
          </button>
        )}
        {copied && <p className="copied">Code Copied!</p>}
        <Favourite isFavourite={isFavourite} onToggle={onToggleFavourite} />

      </div>
    </div>
  );
}
