import { db } from "../../firebase.config";
import { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import "./Quirks.scss";
import Header from "../Header/Header";

function Quirks() {
  const [quirks, setQuirks] = useState([]);
  const [form, setForm] = useState({
    client: "",
    quirk: "",
    desc: "",
  });

  const [popupActive, setPopupActive] = useState(false);
  const quirksCollectionRef = collection(db, "quirks");

  useEffect(() => {
    onSnapshot(quirksCollectionRef, (snapshot) => {
      const quirksData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort quirks alphabetically by client name
      const sortedQuirks = quirksData.sort((a, b) =>
        a.client.localeCompare(b.client)
      );

      setQuirks(sortedQuirks);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.client || !form.quirk || !form.desc) {
      alert("Please fill out all the fields");
      return;
    }

    addDoc(quirksCollectionRef, form);
    setForm({ client: "", quirk: "", desc: "" });
    setPopupActive(false);
  };

  const removeFunc = (id) => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteDoc(doc(db, "quirks", id));
    }
  };

  function filterClients(){
    let clientSelect = document.getElementById('clients');
    clientSelect.onchange = (e) => {
        let clientOption = e.target.value;
        
        let clients = document.querySelectorAll('.function__name');
        clients.forEach(client => {
            console.log(client.innerHTML.toLowerCase())
            if(client.innerHTML.toLowerCase() !== clientOption){
                client.parentNode.style.display = 'none';
            } else {
                client.parentNode.style.display = 'block';
            }
        })
    }
  }

  filterClients();

  return (
    <>
      <Header />
      <div className="App">
        <div className="hidden modalBackdrop"></div>
        <h1>Quirks</h1>

        <div className="filter-container">
          <button
            className="slide_left"
            onClick={() => setPopupActive(!popupActive)}
          >
            Add Quirk
          </button>
          <select name="clients" id="clients">
                <option value="belmond">Belmond</option>
                <option value="monsoon">Monsoon</option>
                <option value="accessorise">Accessorise</option>
                <option value="ocado">Ocado</option>
                <option value="team sport">Team sport</option>
                <option value="liverpool">Liverpool</option>
                <option value="finisterre">Finisterre</option>
                <option value="tfg">Tfg</option>
                <option value="laithwaites">Laithwaites</option>
                <option value="edyn">Edyn</option>
            </select>
        </div>

        <div className="functions">
          {quirks.map((quirk) => (
            <div className="function" key={quirk.id}>
              {/* Display all details of the quirk by default */}
              <h3 className="function__name">{quirk.client}</h3>
              <div className="function__container">
                <h4>Quirk</h4>
                <p
                  className="function__function"
                  dangerouslySetInnerHTML={{ __html: quirk.quirk }}
                ></p>
              </div>
              <div className="function__info">
                <div className="function__description-cont">
                  <h4>Description</h4>
                  <p
                    className="function__description"
                    dangerouslySetInnerHTML={{ __html: quirk.desc }}
                  ></p>
                </div>
              </div>
              <div className="buttons">
                <button
                  className="remove"
                  onClick={() => removeFunc(quirk.id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>

        {popupActive && (
          <div className="popup">
            <div className="popup-inner">
              <h2>Add a Quirk</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Client</label>
                  <input
                    type="text"
                    value={form.client}
                    onChange={(e) =>
                      setForm({ ...form, client: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Quirk</label>
                  <textarea
                    type="text"
                    value={form.quirk}
                    onChange={(e) =>
                      setForm({ ...form, quirk: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <textarea
                    type="text"
                    value={form.desc}
                    onChange={(e) => setForm({ ...form, desc: e.target.value })}
                  />
                </div>
                <div className="buttons">
                  <button typeof="submit">Submit</button>
                  <button
                    type="button"
                    className="remove"
                    onClick={() => setPopupActive(false)}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Quirks;
