
import {React, useEffect} from 'react';
import Funcs from './Components/Functions/Functions';
import Homepage from './Components/Homepage/Homepage';
import Walkdevs from './Components/Walkthroughsdev/Walkthroughsdev';
import Walkcros from './Components/Walkthroughscro/Walkthroughscro';
import Quirks from './Components/Quirks/Quirks';
import Login from './Components/Login/Login';
import PersonalDetails from './Components/PersonalDetails/PersonalDetails';
import Tracking from './Components/Tracking/Tracking';
import AsanaTaskList from './Components/Asana/Asana';
import TestTable from './Components/Calendar/tableTest';

import { useState } from "react";

import {
  // BrowserRouter,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
function App (){

  const [isAuth, setIsAuth] = useState(false);
  //console.log(isAuth)
  useEffect(() => {
    const storedAuth = localStorage.getItem("authToken");
    if (storedAuth) {
      setIsAuth(true);
    }
  }, []);

    return(
      <HashRouter className = "app">
          <Routes>
            <Route path="/" element={<Login setIsAuth={setIsAuth} />} />
            <Route path="/personalDetails" element={isAuth && <PersonalDetails />}/>
            <Route path='/homepage' element = {isAuth && <Homepage />} />
            <Route path='/tracking' element = {isAuth && <Tracking />} />
            <Route path='/TestTable' element = {isAuth && <TestTable />} />
            <Route path='/functions' element = {isAuth && <Funcs />} />
            <Route path='/walkthroughdevs' element = {isAuth && <Walkdevs />} />
            <Route path='/walkthroughcros' element = {isAuth && <Walkcros />} />
            <Route path='/quirks' element = {isAuth && <Quirks />} />
            <Route path='/asana' element = {isAuth && <AsanaTaskList />} />
          </Routes>
      </HashRouter>
      ) 
}

export default App;
