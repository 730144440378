import React, { useEffect } from "react";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase.config";
import { signInWithPopup } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, query, where, getDocs } from "firebase/firestore";
import logo from "../../abstracts/conversio_logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleAuthProvider } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

export default function Login({ setIsAuth }) {
  let navigate = useNavigate();
  let userCollectionRef = collection(db, "users");
  const provider = new GoogleAuthProvider();

  // Create a user on login if the user does not exist based on userId
  const createUser = async (userId, userName, userEmail) => {
    let arr = [];
    const querySnapshot = await getDocs(collection(db, "users"));

    querySnapshot.forEach((doc) => {
      arr.push(doc.data().id);
    });

    if (arr.indexOf(userId) <= -1) {
      addDoc(userCollectionRef, {
        id: userId,
        name: userName,
        email: userEmail,
        alias: "",
        favourites: [],
      });

      navigate("/personalDetails");
    }
  };

  // Checks db if the user alias is empty/filled and navigates to the correct page on login
  const checkUserAlias = async (userId) => {
    const q = query(collection(db, "users"), where("id", "==", userId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      localStorage.setItem("alias", doc.data().alias);

      if (doc.data().alias === "") {
        navigate("/personalDetails");
      } else {
        navigate("/homepage");
      }
    });
  };

  useEffect(() => {
    // Listen for changes in authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated
        setIsAuth(true);
        const userId = user.uid;

        // Generate a unique token for this user
        const authToken = generateAuthToken();

        // Set the authToken in localStorage
        localStorage.setItem("authToken", authToken);

        checkUserAlias(userId);
      } else {
        // User is not authenticated, handle as needed
        setIsAuth(false);
      }
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [setIsAuth]);

  const loginWithGoogle = () => {
    // Allow the user to select a Google account instead of auto-logging in using a saved token
    provider.setCustomParameters({
      prompt: "select_account",
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        // Variables of users' details
        let id = result.user.uid;
        let name = result.user.displayName;
        let emailAddress = result.user.email;
        let splitname = name.split(" ");
        let fName = splitname[0];
        let lName = splitname[1];

        // No need to manually set "isAuth" in localStorage

        // Checks users alias and navigates to the requested page
        checkUserAlias(id);

        // Creates the user account
        createUser(id, name, emailAddress)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            window.location.href =
              "https://giphy.com/gifs/jagyasini-singh-85C4JjBrvEXSYQiLrN/fullscreen";
          });

        // Adds user details to local storage
        localStorage.setItem("firstName", fName);
        localStorage.setItem("lastName", lName);
        localStorage.setItem("email", emailAddress);

        // You don't need to setIsAuth(true) here.
      })
      .catch((error) => {
        console.log(error.code, error.message);
      });
  };

  // Function to generate a unique token for the user
  const generateAuthToken = () => {
    const authToken = uuidv4();
    return authToken;
  };

  return (
    <div className="login">
      <div className="login_btn-section">
        <img className="homepage__logo" src={logo} alt="Conversio logo" />
        <div onClick={loginWithGoogle} className="login_google-btn slide_left">
          <FontAwesomeIcon
            className="login_google-icon"
            color="#7c6bca"
            size="2x"
          />
          <span className="">Login with Google</span>
        </div>
        <div className="login_unauth">Unauthorised Login</div>
      </div>
    </div>
  );
}
